/* eslint-disable import/no-unresolved */
import axios from 'src/utils/axios';

export const getAllSchoolList = async () => {
  const { data } = await axios('school/list');
  return data.data;
};
export const studentProfile = async () => {
  const { data } = await axios('student/profile');
  return data.data;
};
export const packageList = async ({ queryKey, signal }) => {
  const { data } = await axios(`package/list/${queryKey[1]}`, { signal });
  return data.data;
};
export const packageConstituents = async (id) => {
  const { data } = await axios(`package/constituents/${id}`);
  return data.data;
};
export const cartShow = async ({ queryKey, signal }) => {
  const { data } = await axios(`cart/show/${queryKey[1]}`, { signal });
  return data.data;
};
export const cartAdd = async ({ signal, req }) => {
  // console.log(signal, req);
  const { data } = await axios.post(`cart/add/`, req, { signal });
  return data;
};
export const cartUpdate = async ({ signal, req }) => {
  const { data } = await axios.patch(`cart/update/`, req, { signal });
  return data;
};
export const getAllAddress = async ({ signal }) => {
  const { data } = await axios(`address/list`, { signal });
  return data.data;
};
export const addressAdd = async ({ signal, req, isEditMode }) => {
  if (!isEditMode) {
    const { data } = await axios.post(`address/add`, req, { signal });
    return data.data;
  }
  const { data } = await axios.patch(`address/update`, req, { signal });
  return data.data;
};
export const cartDelete = async (id) => {
  const req = { orderID: id };
  const { data } = await axios.delete(`cart/delete/`, { data: req });
  return data.data;
};
export const addressStates = async ({ signal }) => {
  const { data } = await axios(`/address/states`, { signal });
  return data.data;
};
export const parentOrder = async ({ queryKey, signal }) => {
  const parentID = queryKey[1];
  const { data } = await axios.get(`/parent/orders/${parentID}`, { signal });
  return data.data;
};
export const parentSingleOrder = async ({ queryKey, signal }) => {
  const { data } = await axios(`/parent/singleOrder/${queryKey[1]}`, { signal });
  return data.data;
};
export const invoiceGet = async ({ queryKey, signal }) => {
  const [, orderId, vendorId] = queryKey; 
  const { data } = await axios(`/invoice/get/${orderId}?vendorId=${vendorId}`, { signal });
  console.log(data.data, 'data.data');
  return data?.data[0] || {};
};
export const studentProfileUpdate = async ({ signal, req }) => {
  const { data } = await axios.patch(`/student/profile`, req, { signal });
  return data?.data;
};
export const parentUpdateProfile = async ({ signal, req }) => {
  const { data } = await axios.patch(`/parent/updateProfile`, req, { signal });
  return data?.data;
};
export const cartCheckout = async ({ signal, req }) => {
  const { data } = await axios.post(`/cart/checkout/`, req, { signal });
  return data?.data;
};
export const checkPromoCode = async ({ signal, req }) => {
  const { data } = await axios.post(`/cart/checkpromo`, req, { signal });
  return data?.data;
};
export const cartStatusEnquiry = async ({ queryKey, signal }) => {
  const { data } = await axios.post(`/cart/statusEnquiry`, { orderID: queryKey[1] }, { signal });
  return (data && data?.data[0]) || {};
};

export const ticketsAdd = async ({ signal, req }) => {
  const { data } = await axios.post(`/tickets/add`, req, { signal });
  return data;
};
export const ticketsGetCategory = async ({ signal }) => {
  const { data } = await axios(`/tickets/getCategory`, { signal });
  return data?.data[0] || [];
};

export const ticketsRaiseTicket = async ({ signal, req }) => {
  const { data } = await axios.post(`/tickets/raiseTicket`, req, { signal });
  return data;
};

export const ticketsTicketupdate = async ({ signal, req }) => {
  const { data } = await axios.post('tickets/admin/Ticketupdate', req, {
    signal,
  });
  return data;
};

export const uploadImage = async (type, req) => {
  const { data } = await axios.post(`/upload/user/image/${type}`, req, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
