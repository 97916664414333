/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';




// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent:'center',
  alignItems:'center',
  margin: theme.spacing(2, 0, 2, 2),
  background:'url(../images/bg/book-base-login.jfif)',
  backgroundSize:'cover',
  backgroundPosition:'center',
  // backgroundColor:'#343d45',
  backgroundBlendMode:'soft-light'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {/* <HeaderStyle>
         
          <img src={'../../images/logo/logo.png'} alt='logo' style={{width:100}}/>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle> */}

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" color='white'>
              {/* Hi, Welcome Back */}
            </Typography>
            
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 1,textAlign:'center' }}>
                <img src='../../images/logo/logo.png' alt='logo' style={{width:'150px',margin:'auto',marginBottom:'12px'}}/>
                <Typography variant="h4" gutterBottom>
                  Sign in to Pupil Kart
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Parent login</Typography>
              </Box>
            </Stack>
            <LoginForm />
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                For any login related/Number change or OTP related issues, please call on below Pupilkart Support Numbers:
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 1 }}>
                8108192190 / 8108192191
              </Typography>
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
