// routes
import { PATH_DASHBOARD } from './routes/paths';

export const HOST_API = 'https://pupilkart.backendapihub.com/';

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app;

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};
export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
export const deliveryOption = { school: 1, home: 2, either: 3 };

export const productStatusList = [
  { label: 'Deleted', value: 0 },
  { label: 'Cart', value: 1 },
  { label: 'Created', value: 2 },
  { label: 'Processing', value: 3 },
  { label: 'Out For Delivery', value: 4 },
  { label: 'Packed', value: 5 },
  { label: 'Delivered', value: 6 },
  { label: 'Partial Delivered', value: 7 },
  { label: 'Cancelled', value: 8 },
  { label: 'Refund', value: 9 },
  { label: 'RTO', value: 10 },
  { label: 'Pending', value: 11 },
];

export const optionalType = [
  { label: 'Manadatory', value: 1 },
  { label: 'Editable & Manadatory', value: 2 },
  { label: 'Optional', value: 3 },
  { label: 'Editable & Optional', value: 4 },
];

export const devPath = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=';

export const prodPath =
  'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=';
