/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';






// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/home',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <PackgeDetail />, index: true },
        { path: 'student-list', element: <PackgeDetail /> },
        { path: 'school-wise-package', element: <PackageList /> },
        { path: 'package-detail', element: <SinglePackaje /> },
        { path: 'cart', element: <Cart /> },
        { path: 'checkout-product', element: <CheckOutProduct /> },
        { path: 'account', element: <UserAccount /> },
        { path: 'invoice', element: <InvoiceDetails /> },
        { path: 'payment-success', element: <PaymentSuccess /> },
        { path: 'payment-failure', element: <PaymentFailure /> },
        { path: 'payment-cancel', element: <PaymentCancel /> },
        { path: 'payment-status', element: <PaymentStatus /> },
        { path: 'order-history', element: <OrderHistory /> },
        { path: 'ticket', element: <TicketList /> },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'publisher', element: <Publisher /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'client', element: <Client /> },
        { path: 'services', element: <Services /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'refund-cancellation-policy', element: <CancellationPolicy /> },
        { path: 'terms-of-use', element: <TermsOfUse /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// public pages

const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Publisher = Loadable(lazy(() => import('../pages/Publisher')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Client = Loadable(lazy(() => import('../pages/Client')));
const Services = Loadable(lazy(() => import('../pages/Services')));

// Dashboard

const PackgeDetail = Loadable(lazy(() => import('../pages/pakageDetail')));
const SinglePackaje = Loadable(lazy(() => import('../pages/pakageDetail/SinglePackaje')));
const PackageList = Loadable(lazy(() => import('../pages/pakageDetail/PackageList')));
const Cart = Loadable(lazy(() => import('../pages/cart')));

const UserAccount = Loadable(lazy(() => import('../pages/account-detail')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/account-detail/InvoiceDetails')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/CancellationPolicy')));
const OrderHistory = Loadable(lazy(() => import('../pages/order-history')));
const CheckOutProduct = Loadable(lazy(() => import('../pages/checkout')));

const CancellationPolicy = Loadable(lazy(() => import('../pages/CancellationPolicy')));
const TermsOfUse = Loadable(lazy(() => import('../pages/TermsOfUse')));
const PaymentSuccess = Loadable(lazy(() => import('../pages/paymets/PaymentSuccess')));
const PaymentFailure = Loadable(lazy(() => import('../pages/paymets/PaymentFailure')));
const PaymentCancel = Loadable(lazy(() => import('../pages/paymets/PaymentCancel')));
const PaymentStatus = Loadable(lazy(() => import('../pages/paymets/PaymentStatus')));
const TicketList = Loadable(lazy(() => import('../pages/ticket')));
