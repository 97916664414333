/* eslint-disable import/no-unresolved */
/* eslint-disable one-var */
/* eslint-disable import/order */
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField, RHFSelect, RHFRadioGroup } from '../../../components/hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
import { getAllSchoolList } from 'src/pages/api';

// ----------------------------------------------------------------------
export function inputRestrict(e, removeListRegex) {
  const input = e.target;
  const start = input.selectionStart,
    end = input.selectionEnd;
  const initialLen = input.value.length;
  input.value = input.value.replace(removeListRegex, '');
  const lengthDifference = input.value.length - initialLen;
  if (lengthDifference) {
    const newStart = start + lengthDifference;
    const newEnd = end + lengthDifference;
    input.setSelectionRange(newStart, newEnd);
  }
}
export const restrict = {
  email(e) {
    inputRestrict(e, /[^@a-zA-Z-_.\d]/g);
  },
  name(e) {
    inputRestrict(e, /[^a-zA-Z\u0600-\u06FF ]/g);
  },
  digits(e) {
    inputRestrict(e, /\D/g);
  },
  remarks(e) {
    inputRestrict(e, /[^-a-zA-Z(),&.+ \d\u0600-\u06FF]/g);
  },
};
export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setloaing] = useState(false);
  const [schoolList, setSchoollist] = useState([]);

  const [otp, setOtp] = useState('');
  const defaultValues = {
    studentId: '',
    remember: true,
    loginType: 'Student Id',
    mobile: '',
    school: '',
    afterSubmit: '',
  };
  const LoginSchema = Yup.object().shape({
    loginType: Yup.string(),
    studentId: Yup.string()
      .notRequired()
      .when('loginType', {
        is: 'Student Id',
        then: Yup.string().required('Student Id is required'),
      }),
    school: Yup.string()
      .notRequired()
      .when('loginType', {
        is: 'Student Id',
        then: Yup.string().required('School is required'),
      }),
    mobile: Yup.string()
      .notRequired()
      .when('loginType', {
        is: 'Mobile No.',
        then: Yup.string().trim().required('Mobile is required'),
      }),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (formData) => {
    try {
      let req = {};
      if (loginType === 'Student Id') {
        req = { loginType: 1, schoolId: formData.school, username: formData.studentId, password: '123456' };
      }
      if (loginType === 'Mobile No.') {
        req = { loginType: 2, username: formData.mobile, password: '123456' };
      }
      const { data } = await axios.post('auth/login', req);

      setOpen(true);
    } catch (error) {
      console.error(error);
      // setOpen(true);
      // reset();
      // console.log(error.message);
      setError('afterSubmit', { ...error, message: error.message });
    }
  };
  const loginType = watch('loginType');

  const handleVerify = async () => {
    if (otp) {
      setloaing(true);
      try {
        await login({ ...getValues(), otp });
        setloaing(false);
      } catch (error) {
        setloaing(false);
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };
  useEffect(() => {
    Promise.all([getAllSchoolList()])
      .then((data) => {
        // console.log(data[0]);
        setSchoollist(data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {!!errors.afterSubmit && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errors.afterSubmit.message || 'Something Went Wrong'}
        </Alert>
      )}
      {!open ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFRadioGroup
              name="loginType"
              options={['Student Id', 'Mobile No.']}
              onInputChange={(e) => {
                if (e.target.value === 'Student Id') {
                  setValue('mobile', ''); // Mobile number field ko clear karega
                } else {
                  setValue('studentId', ''); // Student ID field ko clear karega
                  setValue('school', ''); // School field bhi clear karega
                }
              }}
            />
            {loginType === 'Student Id' ? (
              <>
                <RHFSelect name="school" label="Select School" InputLabelProps={{ shrink: true }}>
                  <option value="">Select School</option>
                  {schoolList &&
                    schoolList?.map((el) => (
                      <option value={el.schoolID} key={el.schoolID}>
                        {el.schoolName}
                      </option>
                    ))}
                </RHFSelect>
                <RHFTextField
                  name="studentId"
                  label={'Student Id'}
                  // type={showPassword ? 'text' : 'password'}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter Student Id"
                />
              </>
            ) : (
              <RHFTextField
                name="mobile"
                label="Mobile No."
                InputLabelProps={{ shrink: true }}
                placeholder="Enter Mobile No."
                inputProps={{ maxLength: 10 }}
                onInput={restrict.digits}
              />
            )}
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 3 }}>
            Login
          </LoadingButton>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            color="inherit"
            onClick={() => {
              navigate('/');
            }}
          >
            Back to Home
          </Button>
        </FormProvider>
      ) : (
        <Stack spacing={3}>
          <TextField
            variant="outlined"
            color="primary"
            label="OTP"
            placeholder="Enter OTP"
            fullWidth
            inputProps={{ maxLength: 6 }}
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <LoadingButton
            size="large"
            color="primary"
            variant="contained"
            onClick={handleVerify}
            loading={loading}
            disabled={!otp}
          >
            Verify OTP
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
            size="large"
          >
            Back to Login
          </Button>
        </Stack>
      )}
    </>
  );
}
