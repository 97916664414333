/* eslint-disable react/self-closing-comp */

import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <footer id="wn__footer" className="footer__area bg__cat--8 brown--color">
      <div className="footer-static-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer__widget footer__menu">
                <div className="ft__logo">
                  <Link to="/">
                    <img src="../../images/logo/logo.png" alt="logo" style={{ margin: 'auto', width: '127px' }} />
                  </Link>
                  <p>
                    Education is an investment that never gets unpaid. An intelligent mind can shape a wonderful future
                    with the help of correct counselling and an efficient education system
                  </p>
                </div>
                <div className="footer__content">
                  {/* <ul className="social__net social__net--2 d-flex justify-content-center">
                    <li>
                      <a href="https://www.facebook.com/Book-Base-1101577813251381/" target="_blank"  rel='noreferrer'>
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://plus.google.com/+bookbasein" target="_blank" rel='noreferrer'>
                        <i className="bi bi-google"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/KunalBookbase" target="_blank" rel='noreferrer'>
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bi bi-youtube"></i>
                      </a>
                    </li>
                  </ul> */}
                  <ul className="mainmenu d-flex justify-content-center">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    {/* <li>
                      <Link to="/publisher">Publisher</Link>
                    </li>
                    <li>
                      <Link to="/client">Client</Link>
                    </li> */}
                    <li>
                      <Link to="/auth/login">Sudent/Parent Login</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                    <li>
                      <Link to="/refund-cancellation-policy">refund & cancellation policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use">Terms of Use</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright__wrapper">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="copyright">
                <div className="copy__right__inner text-start">
                  <p>
                    &copy; 2023, Pupilkart. Copyright All Right Reserved. Designed and Developed by{' '}
                    <a href="https://dbuggedprogrammers.com" target='_blank' rel='noreferrer'>D’Bugged Programmers.</a>{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="payment text-end">
                <img src="images/icons/payment.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
