/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-has-content */

import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TemporaryDrawer from 'src/components/MobileNav';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

export default function MainHeader() {
  const { user, logout } = useAuth();
  const { pathname } = useLocation();
  const naavigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileClick = (e) => {
    setOpen(true);
    setAnchorEl(e.target);
  };
  const handleClick = () => {
    naavigate('/auth/login');
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (openMobileMenu) {
      setOpenMobileMenu(false);
    }
  }, [pathname]);
  return (
    <>
      {openMobileMenu && (
        <TemporaryDrawer
          open={openMobileMenu}
          onClose={() => {
            setOpenMobileMenu(false);
          }}
        />
      )}
      <header
        id="wn__header"
        className="header__area sticky__header"
        style={{ borderBottom: '2px solid #02a7c0' }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6 col-lg-2">
              <div className="logo">
                <a href="/">
                  <img src="../../images/logo/logo.png" alt="logo images" style={{ width: '85px' }} />
                </a>
              </div>
            </div>
            <div className="col-lg-8 d-none d-lg-block">
              <nav className="mainmenu__nav">
                <ul className="meninmenu d-flex justify-content-start">
                  <li className="drop with--one--item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="drop with--one--item">
                  <Link to="/about-us">About</Link>
                </li>
                  {/* <li className="drop with--one--item">
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="drop with--one--item">
                    <Link to="/publisher">Publisher</Link>
                  </li>
                  <li className="drop with--one--item">
                    <Link to="/client">Client</Link>
                  </li> */}
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                  {!user && (
                    <li>
                      <Link to="/auth/login">Parent Login</Link>
                    </li>
                  )}
                  {user && (
                    <li>
                      <Link to="/home/student-list">Package List</Link>
                    </li>
                  )}
                  {user && (
                    <li>
                      <Link to="/home/ticket">Support</Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className="col-md-6 col-sm-6 col-6 col-lg-2">
              <ul className="header__sidebar__right d-flex justify-content-end align-items-center">
                {/* <li className="wishlist">
                <a href="#"></a>
              </li> */}
                <li className="shopcart">
                  <Link className="cartbox_active text-center" to={user ? '/home/cart' : '/auth/login'}>
                    <img
                      src="../../images/icons/cart.png"
                      alt="cart"
                      className="img-fluid"
                      style={{ width: '40px', margin: 'auto' }}
                    />
                    Cart
                  </Link>
                </li>
                <li className="setting__bar__icon">
                  <a
                    className="setting__active text-center"
                    onClick={user ? handleProfileClick : handleClick}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src="../../images/icons/user.png"
                      alt="cart"
                      className="img-fluid"
                      style={{ width: '40px', margin: 'auto' }}
                    />
                    {user ? 'My Account' : 'Parent Login'}
                  </a>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,

                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem
                      onClick={() => {
                        naavigate('/home/account');
                        handleClose();
                      }}
                    >
                      My Account
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        naavigate('/home/student-list');
                        handleClose();
                      }}
                    >
                      Packages
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        naavigate('/home/ticket');
                        handleClose();
                      }}
                    >
                      Report an Issue
                    </MenuItem> */}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        logout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </li>
                <li className="shopcart ">
                  <div className="d-sm-block d-md-block d-block d-lg-none">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setOpenMobileMenu(true);
                      }}
                    >
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </IconButton>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <nav style={{ borderBottom: '2px solid #02a7c0' }} className="d-block d-sm-block d-md-block d-lg-none">
        <ul className="mobile_menu11 d-flex justify-content-center align-items-center py-3">
          <li>
            <Link to="/home/student-list">My Package</Link>
          </li>
          <li>
            <Link to="/home/order-history">My Order</Link>
          </li>
          <li>
            <Link to="/home/ticket">Support</Link>
          </li>
          {/* <li><a></a></li> */}
        </ul>
      </nav>
    </>
  );
}
