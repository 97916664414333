/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const menuList = [
  { label: 'Home', path: '/' },
  { label: 'About', path: '/about-us' },
  // { label: 'Services', path: '/services' },
  // { label: 'Publisher', path: '/publisher' },
  // { label: 'Client', path: '/client' },
  { label: 'Contact', path: '/contact-us' },
];
export default function TemporaryDrawer({ open, onClose }) {
  const { user } = useAuth();
  const navigate = useNavigate()
  return (
    <div>
      <Drawer anchor={'right'} open={open} onClose={onClose}>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            {menuList.map((item, index) => (
              <ListItem key={item} disablePadding>
                <ListItemButton onClick={()=>{navigate(item.path)}}>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
            {user ? (
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={'Package List'} onClick={()=>{navigate('/home/student-list')}}/>
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={'Parent Login'} onClick={()=>{navigate('/auth/login')}}/>
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
